.step {
  display: flex;
  flex-direction: row;
  justify-content: 'flex-start';
  align-items: center;
  margin-bottom: 16px;
}

.responsibilitiesGrid {
  align-items: center;
}
.vStepper {
  position: relative;
  text-align: center;
}
.circle {
  background-color: #fff;
  border: 3px solid #23a531;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: inline-block;
}
.line {
  top: 23px;
  left: 18px;
  height: 160%;
  position: absolute;
  border-left: 2px solid #8d8c9c;
  border-left-style: dotted;
}
.responsibilitiesTitle {
  color: #1c1939;
  font-size: 16px !important;
  font-family: Poppins-SemiBold;
}

.step:last-child .line {
  border-left: 2px solid #fff;
  z-index: -1;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.OutlinedTextFieldCSSStyle {
  padding: 10.5px 10px !important;
}

/* media queries */

@media screen and (min-width: 600px) and (max-width: 1023px) {
  .circle {
    border: 2px solid #23a531;
    width: 15px;
    height: 15px;
  }
  .line {
    top: 23px;
    left: 8px;
    height: 150%;
  }
  .responsibilitiesTitle {
    font-size: 14px !important;
  }
}
/* custom modal */
@media screen and (max-width: 900px) {
  .icon {
    width: 30px;
    height: 30px;
  }
}
